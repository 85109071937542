import axios from 'axios';

// const axiosServices = axios.create({ baseURL: 'http://localhost:8800/api/' });

// Dev server API's
// const axiosServices = axios.create({ baseURL: 'https://node.gokarobaar.com/api/' });
// const axiosServices = axios.create({ baseURL: 'https://dev-api.inspectnbuy.com/api/' });
// const axiosServices = axios.create({ baseURL: 'https://qa-api.inspectnbuy.com/api/' });

// Production server API
const axiosServices = axios.create({ baseURL: 'https://api.inspectnbuy.com/api/' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
axiosServices.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('serviceToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (token && refreshToken) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.refreshToken = `Bearer ${refreshToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken) {
          throw new Error('Refresh token not found');
        }

        const response = await axios.post(`${axiosServices.defaults.baseURL}store/get-token`, { refreshToken });

        const { access_token } = response.data.result;

        if (!access_token) {
          throw new Error('Failed to obtain new access token');
        }

        localStorage.setItem('serviceToken', access_token);
        axiosServices.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

        return axiosServices(originalRequest);
      } catch (refreshError) {
        const storeId = localStorage.getItem('storeId');

        console.error('Token refresh failed:', refreshError);

        if (storeId) {
          await axios.post(`${axiosServices.defaults.baseURL}store/logout`, { id: storeId });
        }

        localStorage.clear();
        window.location.pathname = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosServices;
